


































































































import {Component, Vue} from "vue-property-decorator";
import ErrorText from "@/views/user/componets/ErrorText.vue";
import '@/assets/css/user/main.css';
import router from "@/router";
import {EMAIL} from "@/interface/email";
import AUTH_TYPE = EMAIL.AUTH_TYPE;

@Component({
    components: {ErrorText}
})

export default class Register extends Vue {

    $refs!: { [key: string]: any }
    token : string;
    userPassword: string;
    userConfirmPassword: string;
    userEmail: string;
    userTernsOfServiceAgree: boolean;
    userPrivacyPolicyAgree: boolean;
    isAblePw: boolean;
    isAbleEmail: boolean;
    isEqualsPw: boolean;
    isRegEmail: boolean;
    okPw: boolean;
    okEmail: boolean;
    okConfirmPw: boolean;


    constructor() {
        super();
        this.userPassword = "";
        this.userConfirmPassword = "";
        this.token = '';
        this.userEmail = "";
        this.userTernsOfServiceAgree = false;
        this.userPrivacyPolicyAgree = false;
        this.isAblePw = true;
        this.isAbleEmail = true;
        this.isEqualsPw = true;
        this.isRegEmail = true;
        this.okConfirmPw = false;
        this.okPw = false;
        this.okEmail = false;

    }

    createSendData() {
        return {
            userEmail: this.userEmail,
            userPassword: this.userPassword,
            userTernsOfServiceAgree: this.userTernsOfServiceAgree,
            userPrivacyPolicyAgree: this.userPrivacyPolicyAgree,
        }
    };

    async validate() {

        if (!this.okEmail) {
            return {
                result: false,
                message: "이메일을 확인해주세요"
            };
        } else if (!this.okPw) {
            return {
                result: false,
                message: "패스워드를 확인해주세요"
            };
        } else if(!this.okConfirmPw) {
            return {
              result: false,
              message: "패스워드를 일치시켜주세요"
            };
        }else if (!this.userTernsOfServiceAgree) {
            return {
                result: false,
                message: "이용약관 동의를 해주세요"
            };

        } else if (!this.userPrivacyPolicyAgree) {
            return {
                result: false,
                message: "개인 정보 수집 및 이용 동의를 해주세요"
            };

        } else {
            return {
                result: true,
                message: '회원가입 성공'
            };

        }
    }

    async createUser() {
        const {result, message} = await this.validate();

        if (result) {
            const sendData = this.createSendData();
            const userInfo = await this.axios.post('/user/register', sendData);


            if(userInfo){
              const userData = {
                email: this.userEmail,
                type : AUTH_TYPE.NEW_AUTH,
              }

              const {data} = await Vue.axios({
                url: '/mail/authEmailSend',
                method: 'POST',
                data: userData,
              });

              if (data.result) {
                Vue.$toast.open({
                  message: '이메일 인증을 통해 회원가입을 완료해주세요',
                  type: 'warning',
                  duration: 5000
                });

                await router.push('/user/login');

              }else{
                Vue.$toast.open({
                  message: '회원가입 에러 발생 고객센터에 문의바랍니다',
                  type: 'error',
                  duration: 5000
                });
              }
            }
        } else {
            this.$bvModal.msgBoxOk(message)
                  .then(value => {
                      return false;
            })
        }

    }

    async pwCheck() {
        if (this.userPassword) {
            const sendData = {
                userPassword: this.userPassword
            }
            const {data} = await Vue.axios({
                url: '/user/pwCheck',
                method: 'POST',
                data: sendData
            });

            this.isAblePw = data.result;
            this.okPw = data.result;
            if(this.okPw && this.userConfirmPassword) await this.pwEqualsCheck();
        }else{
          this.isAblePw = false;
          this.okPw = false;
        }
    }

    async pwEqualsCheck() {

        if (this.userConfirmPassword === this.userPassword) {
            const sendData = {
                userPassword: this.userPassword,
                userConfirmPassword: this.userConfirmPassword
            }

            const {data} = await Vue.axios({
                url: '/user/pwEqualCheck',
                method: 'POST',
                data: sendData
            })

            this.okConfirmPw = data.result;
            this.isEqualsPw = data.result;
        }else{
          this.okConfirmPw = false;
          this.isEqualsPw = false;
        }

    }

    async emailCheck() {

        //공백제거
        if (this.userEmail) {

            const sendData = {
                userEmail: this.userEmail
            }

            const emailRegCheck = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(this.userEmail)

            // nullCheck , 정규 표현석 reg test  처리
            const {data} = await Vue.axios({
                url: '/user/emailCheck',
                method: 'POST',
                data: sendData
            })

            if (!emailRegCheck || !data.isRegEmail) {
                this.okEmail = false;
                this.isRegEmail = false;
            } else {
                data.isAbleEmail ? this.isAbleEmail = true : this.isAbleEmail = false;
                this.isRegEmail = true;
                this.okEmail = data.isAbleEmail;

            }
        }

    }
    mounted() {
    }

}
