
export namespace EMAIL{
    export const enum TEMPLATE_TYPE {
        INVITATION = 'invitation',
        EMAIL_AUTH = 'emailAuth',
        FIND = 'findPW',
        BOARD_INVITATION = 'boardInvitation',
    }

    export const enum AUTH_TYPE {
        FIND = '비밀번호 찾기',
        RE_AUTH = '재인증',
        NEW_AUTH = '신규가입',
    }

    export const enum PARTICIPANT_INVITATION_VARIABLE {
        USER_NAME = '[USER_NAME]',
        TITLE = '[TITLE]',
        START_DATETIME = '[START_DATETIME]',
        INTERVIEW_LENGTH = '[INTERVIEW_LENGTH]',
        JOIN_PASSWORD = '[JOIN_PASSWORD]',
        REWORD = '[REWORD]',
        JOIN_LINK = '[JOIN_LINK]',
        MANAGER_NAME = '[MANAGER_NAME]',
        MANAGER_TEL = '[MANAGER_TEL]',
    }
}
